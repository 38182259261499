
import {
  Guid, Localizations, LocalizationStrings, MediaServerApiMediaData, MediaServerApiMediaInfo, SystemStatus
} from '@/@models'
import PortalApiBase from "@/api/portal-api-base";
import appUtils from "@/utils/app-utils";
import {ApiEmptyErrorCallback, ApiEmptySuccessCallback, ApiErrorCallback, ApiSuccessCallback} from "@/api/api-base";

export default new class extends PortalApiBase {
  getMediaTesterMediaInfo(mediaUrl: string, includeTranscript: boolean, transcriptLang: string | null, includeRaw: boolean,
                          successCb: ApiSuccessCallback<MediaServerApiMediaData<MediaServerApiMediaInfo>>,
                          errorCb: ApiErrorCallback<MediaServerApiMediaData<MediaServerApiMediaInfo>>) {
    return this._defaultPostResponse('/tester/media-tester-media-info', {
      telegramInitData: appUtils.getTelegramInitData(),
      mediaUrl: mediaUrl,
      includeTranscript: includeTranscript,
      transcriptLang: transcriptLang,
      includeRaw: includeRaw,
    }, null, successCb, errorCb);
  }
}
