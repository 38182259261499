import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import TransitView from "@/views/_layout/TransitView.vue";
import FullPageLayout from "@/views/_layout/FullPageLayout.vue";
import AdminPageLayout from "@/views/_layout/AdminPageLayout.vue";

import HomePage from '@/views/home/Home.vue'
import PremiumPage from '@/views/home/Premium.vue'

import PaymentAccountLinkPage from '@/views/payment/PaymentAccountLink.vue'
import PaymentLinkPage from '@/views/payment/PaymentLink.vue'
import PaymentResultPage from '@/views/payment/PaymentResult.vue'
import PaymentManagementPortalPage from '@/views/payment/PaymentManagementPortal.vue'
import ChannelSupportPage from '@/views/payment/ChannelSupport.vue'

import ChannelCausesAndCharityPage from '@/views/channel/ChannelCausesAndCharity.vue'

import ChannelAiAgents from '@/views/ai/ChannelAiAgents.vue'

import KnowledgeBaseArticlePage from '@/views/knowledge-base/KnowledgeBaseArticle.vue'

import TelegramDeepLinkPage from '@/views/telegram/TelegramDeepLink.vue'

// admin
import SystemStatusPage from '@/views/admin/system-status/SystemStatus.vue'
import AdminLocalizationEditorPage from '@/views/admin/localization/LocalizationEditor.vue'
import AccountsTablePage from '@/views/admin/account/AccountsTable.vue'
import AiAgentsTablePage from '@/views/admin/ai/AiAgentsTable.vue'
import EditAiAgentPage from '@/views/admin/ai/EditAiAgent.vue'
import AiLanguagesTablePage from '@/views/admin/ai/AiLanguagesTable.vue'
import EditAiLanguagePage from '@/views/admin/ai/EditAiLanguage.vue'
import AiRolesTablePage from '@/views/admin/ai/AiRolesTable.vue'
import EditAiRolePage from '@/views/admin/ai/EditAiRole.vue'
import AiTonesTablePage from '@/views/admin/ai/AiTonesTable.vue'
import EditAiTonePage from '@/views/admin/ai/EditAiTone.vue'
import AiActionsTablePage from '@/views/admin/ai/AiActionsTable.vue'
import EditAiActionPage from '@/views/admin/ai/EditAiAction.vue'
import MediaTesterPage from '@/views/admin/tester/MediaTester.vue'
import PortalSubscriptionsTablePage from '@/views/admin/portal/PortalSubscriptionsTable.vue'
import EditPortalSubscriptionPage from '@/views/admin/portal/EditPortalSubscription.vue'

import CallbackPage from '@/views/system/Callback.vue'
import Error404Page from '@/views/system/Error404.vue'
import useStore from "@/store";

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: FullPageLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomePage
      },
      {
        path: '/premium',
        name: 'premium',
        component: PremiumPage
      },
      {
        path: '/telegram-deep-link',
        name: 'telegram-deep-link',
        component: TelegramDeepLinkPage
      },
      {
        path: '/p/:paymentLinkPublicId',
        name: 'payment-link',
        component: PaymentLinkPage,
        meta: {
          showFooter: true
        }
      },
      {
        path: '/channel/:channelPublicId',
        component: TransitView,
        children: [
          {
            path: '',
            name: 'channel-main',
            redirect: { name: 'channel-support' }
          },
          {
            path: 'support',
            name: 'channel-support',
            component: ChannelSupportPage
          },
          {
            path: 'iframe',
            name: 'channel-iframe',
            component: ChannelSupportPage,
            meta: {
              iframeMode: true
            }
          },
        ],
        meta: {
          showFooter: true
        }
      },
      {
        path: '/channel-admin',
        component: TransitView,
        children: [
          {
            path: ':channelPublicId/causes-and-charity',
            name: 'channel-admin-causes-and-charity',
            component: ChannelCausesAndCharityPage
          },
          {
            path: 'ai-agents',
            name: 'channel-admin-ai-agents',
            component: ChannelAiAgents,
          },
        ]
      },
      {
        path: '/payment',
        component: TransitView,
        children: [
          {
            path: 'account-link',
            name: 'payment-account-link',
            component: PaymentAccountLinkPage,
          },
          {
            path: 'management-portal/:channelPublicId?',
            name: 'payment-management-portal',
            component: PaymentManagementPortalPage,
          },
          {
            path: 'completed/:paymentLinkPublicId',
            name: 'payment-completed',
            component: PaymentResultPage
          },
          {
            path: 'canceled/:paymentLinkPublicId',
            name: 'payment-canceled',
            component: PaymentResultPage
          },
        ],
        meta: {
          showFooter: true
        }
      },
      {
        path: '/knowledge-base',
        component: TransitView,
        children: [
          {
            path: 'article/:publicId',
            name: 'knowledge-base-article',
            component: KnowledgeBaseArticlePage,
          },
        ],
        meta: {
          showFooter: true
        }
      },
      {
        path: '/callback/:action',
        name: 'callback',
        component: CallbackPage
      },
      {
        path: '/error-404',
        name: 'error-404',
        component: Error404Page
      },
    ]
  },
  // admin
  {
    path: '/admin',
    component: AdminPageLayout,
    redirect: { name: 'admin-account' },
    children: [
      {
        path: 'account',
        name: 'admin-account',
        component: AccountsTablePage,
        meta: {
          title: "Accounts",
          icon: "fas fa-user"
        }
      },
      {
        path: 'ai',
        component: TransitView,
        children: [
          {
            path: 'ai-agents',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-ai-agents-table',
                component: AiAgentsTablePage,
                meta: {
                  title: "AI Agents",
                  pageTitle: "AI Agents"
                }
              },
              {
                path: 'create',
                name: 'admin-create-ai-agent',
                component: EditAiAgentPage,
                meta: {
                  title: "Create AI Agent",
                  pageTitle: "Create AI Agent",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-ai-agent',
                component: EditAiAgentPage,
                meta: {
                  title: "Edit AI Agent",
                  pageTitle: "Edit AI Agent",
                }
              },
            ],
            meta: {
              title: "AI Agents",
            }
          },
          {
            path: 'ai-languages',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-ai-languages-table',
                component: AiLanguagesTablePage,
                meta: {
                  title: "AI Languages",
                  pageTitle: "AI Languages"
                }
              },
              {
                path: 'create',
                name: 'admin-create-ai-language',
                component: EditAiLanguagePage,
                meta: {
                  title: "Create AI Language",
                  pageTitle: "Create AI Language",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-ai-language',
                component: EditAiLanguagePage,
                meta: {
                  title: "Edit AI Language",
                  pageTitle: "Edit AI Language",
                }
              },
            ],
            meta: {
              title: "AI Languages",
            }
          },
          {
            path: 'ai-roles',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-ai-roles-table',
                component: AiRolesTablePage,
                meta: {
                  title: "AI Roles",
                  pageTitle: "AI Roles"
                }
              },
              {
                path: 'create',
                name: 'admin-create-ai-role',
                component: EditAiRolePage,
                meta: {
                  title: "Create AI Role",
                  pageTitle: "Create AI Role",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-ai-role',
                component: EditAiRolePage,
                meta: {
                  title: "Edit AI Role",
                  pageTitle: "Edit AI Role",
                }
              },
            ],
            meta: {
              title: "AI Roles",
            }
          },
          {
            path: 'ai-tones',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-ai-tones-table',
                component: AiTonesTablePage,
                meta: {
                  title: "AI Tones",
                  pageTitle: "AI Tones"
                }
              },
              {
                path: 'create',
                name: 'admin-create-ai-tone',
                component: EditAiTonePage,
                meta: {
                  title: "Create AI Tone",
                  pageTitle: "Create AI Tone",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-ai-tone',
                component: EditAiTonePage,
                meta: {
                  title: "Edit AI Tone",
                  pageTitle: "Edit AI Tone",
                }
              },
            ],
            meta: {
              title: "AI Tones",
            }
          },
          {
            path: 'ai-actions',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-ai-actions-table',
                component: AiActionsTablePage,
                meta: {
                  title: "AI Actions",
                  pageTitle: "AI Actions"
                }
              },
              {
                path: 'create',
                name: 'admin-create-ai-action',
                component: EditAiActionPage,
                meta: {
                  title: "Create AI Action",
                  pageTitle: "Create AI Action",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-ai-action',
                component: EditAiActionPage,
                meta: {
                  title: "Edit AI Action",
                  pageTitle: "Edit AI Action",
                }
              },
            ],
            meta: {
              title: "AI Actions",
            }
          },
        ],
        meta: {
          title: "AI",
          icon: "fas fa-robot",
        }
      },
      {
        path: 'portal',
        component: TransitView,
        children: [
          {
            path: 'subscriptions',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-portal-subscriptions-table',
                component: PortalSubscriptionsTablePage,
                meta: {
                  title: "Premium Subscriptions",
                  pageTitle: "Premium Subscription"
                }
              },
              {
                path: 'create',
                name: 'admin-create-portal-subscription',
                component: EditPortalSubscriptionPage,
                meta: {
                  title: "Create Premium Subscription",
                  pageTitle: "Create Premium Subscription",
                }
              },
              {
                path: ':id/edit',
                name: 'admin-edit-portal-subscription',
                component: EditPortalSubscriptionPage,
                meta: {
                  title: "Edit Premium Subscription",
                  pageTitle: "Edit Premium Subscription",
                }
              },
            ],
            meta: {
              title: "Premium Subscriptions",
            }
          }
        ],
        meta: {
          title: "Portal",
          icon: "fas fa-suitcase",
        }
      },
      {
        path: 'tester',
        component: TransitView,
        children: [
          {
            path: 'media',
            component: TransitView,
            children: [
              {
                path: '',
                name: 'admin-portal-media-tester',
                component: MediaTesterPage,
                meta: {
                  title: "Media Tester"
                }
              }
            ],
            meta: {
              title: "Media Tester",
            }
          }
        ],
        meta: {
          title: "Tester",
          icon: "fas fa-flask",
        }
      },
      {
        path: 'localization-editor',
        name: 'admin-localization-editor',
        component: AdminLocalizationEditorPage,
        meta: {
          title: "Localization",
          icon: "fas fa-language"
        }
      },
      {
        path: 'system-status',
        name: 'admin-system-status',
        component: SystemStatusPage,
        meta: {
          title: "System Status",
          icon: "fas fa-circle-info"
        }
      },
    ]
  },
  // Ignore
  {
    path: '',
    children: [
      {
        path: '/api/*',
        name: 'api',
      },
      {
        path: '/static-pages/*',
        name: 'static-pages',
      },
    ],
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404'
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const store = useStore()

  return next();
})

export default function useRouter() {
  return router;
}
