













































































































import {computed, defineComponent, onMounted, ref, watch} from "@vue/composition-api";
import useStore from "@/store";
import {
  DateTime, Guid, MediaServerApiMediaData, MediaServerApiMediaInfo
} from "@/@models";
import useConfig from "@/utils/useConfig";
import useNotifications from "@/components/useNotifications";
import helpers from "@/utils/helpers";
// @ts-ignore
import JsonViewer from "vue-json-viewer";
import testerPortalApi from "@/api/portal/tester-portal-api";
import BTextInputFormGroup from "@/views/_controls/BTextInputFormGroup.vue";

export default defineComponent({
  components: {
    BTextInputFormGroup,
    JsonViewer
  },
  setup(props, context) {
    const config = useConfig()
    const { showErrorNotification } = useNotifications(context)

    const isButtonsDisabled = ref(false)
    const mediaUrl = ref<string | null>(null)
    const mediaTranscriptLang = ref<string | null>(null)
    const mediaData = ref<MediaServerApiMediaData<MediaServerApiMediaInfo> | null>(null)

    const isDataLoading = ref(false)
    const updateMediaData = () => {
      if (!helpers.isNotEmpty(mediaUrl.value)) return

      isButtonsDisabled.value = true
      isDataLoading.value = true

      testerPortalApi.getMediaTesterMediaInfo(mediaUrl.value, true,
        mediaTranscriptLang.value, true, result => {
        mediaData.value = result

        isButtonsDisabled.value = false
        isDataLoading.value = false
      }, errorMessage => {
        showErrorNotification(errorMessage)

        isButtonsDisabled.value = false
        isDataLoading.value = false
      })
    }

    return {
      config,
      helpers,

      isButtonsDisabled,
      isDataLoading,

      mediaUrl,
      mediaTranscriptLang,
      mediaData: mediaData as unknown as MediaServerApiMediaData<MediaServerApiMediaInfo>,

      updateMediaData,
    }
  }
})
